<template>
  <c-box id="kuisioner">
    <Header />
    <c-box paddingTop="30px" paddingBottom="80px" :px="['20px', '0']">
      <c-box w="100%" maxWidth="1200px" mx="auto">
        <Breadcrumb
          :items="[
            {
              label: 'Beranda',
              href: $router.resolve({ name: 'client.index' }).href,
            },
            {
              label: 'Kuisioner',
              href: $router.resolve({ name: 'client.kuisioner' }).href,
              isCurrent: true,
            },
          ]"
        />
        <hr v-chakra mt="30px" />
        <Steps :current="3" :total="7" v-chakra mt="30px" />

        <c-box w="100%" maxWidth="590px" mx="auto" marginTop="50px">
          <c-box textAlign="center">
            <c-text
              color="brand.900"
              fontFamily="roboto"
              textTransform="uppercase"
              fontWeight="700"
              fontSize="16px"
              marginBottom="10px"
            >
              Kuisioner (3/7)
            </c-text>
            <c-heading
              fontWeight="600"
              fontSize="36px"
              lineHeight="54px"
              color="black.900"
            >
              Data Pola &amp; Riwayat Makan
            </c-heading>
          </c-box>
          <form @submit.prevent="submit">
            <c-box
              marginTop="80px"
              v-chakra="{
                'input[type=radio]:checked+.css-1nskzgf': {
                  bg: 'brand.900',
                  borderColor: 'brand.900',
                },
                'input[type=checkbox]:checked+.css-fd41dq': {
                  bg: 'brand.900',
                  borderColor: 'brand.900',
                },
                div: {
                  fontSize: '18px',
                },
              }"
            >
              <c-form-control
                :is-disabled="!isEditable"
                is-required
                :is-invalid="$v.foodPreference.$invalid"
                mb="30px"
              >
                <c-form-label
                  fontSize="16px"
                  color="#555555"
                  fontWeight="400"
                  fontFamily="Roboto"
                >
                  Preferensi Makanan
                </c-form-label>

                <c-select
                  height="62px"
                  placeholder="Preferensi Makanan"
                  v-model="foodPreference"
                >
                  <option value="Tidak ada batasan">Tidak ada batasan</option>
                  <option value="Pescatarian">Pescatarian</option>
                  <option value="Lacto-Ovo Vegetarian">
                    Lacto-Ovo Vegetarian
                  </option>
                  <option value="Ovo-Vegetarian">Ovo-Vegetarian</option>
                  <option value="Vegan">Vegan</option>
                </c-select>
              </c-form-control>
              <c-form-control
                :is-disabled="!isEditable"
                marginBottom="30px"
                is-required
                :is-invalid="$v.breakfastHabits.$invalid"
              >
                <c-form-label
                  fontSize="16px"
                  color="#555555"
                  fontWeight="400"
                  fontFamily="Roboto"
                >
                  Bagaimana kebiasaan sarapan kamu?
                </c-form-label>

                <c-select
                  height="62px"
                  placeholder="Bagaimana kebiasaan sarapan kamu?"
                  v-model="breakfastHabits"
                >
                  <option value="Selalu">Selalu</option>
                  <option value="Kadang-kadang">Kadang-kadang</option>
                  <option value="Tidak pernah">Tidak pernah</option>
                </c-select>
              </c-form-control>
              <c-form-control
                :is-disabled="!isEditable"
                marginBottom="30px"
                is-required
                :is-invalid="$v.breakfastFoodType.$invalid"
              >
                <c-form-label
                  fontSize="16px"
                  color="#555555"
                  fontWeight="400"
                  fontFamily="Roboto"
                >
                  Jenis makanan yang kamu konsumsi saat sarapan?
                </c-form-label>

                <c-radio-group
                  variant-color="brand"
                  v-model="breakfastFoodType"
                >
                  <c-radio
                    value="Makanan yang ringan saja atau konsumsi minuman saja, hanya untuk mengganjal perut sebelum beraktivitas (misalnya: minum susu, kopi, teh, roti isi, cemilan tradisional seperti lontong, gorengan, atau kue-kue jajanan pasar)"
                    align-items="flex-start"
                    size="lg"
                  >
                    Makanan yang ringan saja atau konsumsi minuman saja, hanya
                    untuk mengganjal perut sebelum beraktivitas (misalnya: minum
                    susu, kopi, teh, roti isi, cemilan tradisional seperti
                    lontong, gorengan, atau kue-kue jajanan pasar)
                  </c-radio>
                  <c-radio
                    align-items="flex-start"
                    size="lg"
                    value="Makanan yang mengenyangkan perut (nasi atau roti atau kentang dengan lauk-pauk)"
                  >
                    Makanan yang mengenyangkan perut (nasi atau roti atau
                    kentang dengan lauk-pauk)
                  </c-radio>
                  <c-radio
                    align-items="flex-start"
                    size="lg"
                    value="Hampir tidak pernah sarapan"
                  >
                    Hampir tidak pernah sarapan
                  </c-radio>
                </c-radio-group>
              </c-form-control>
              <c-form-control
                :is-disabled="!isEditable"
                marginBottom="30px"
                is-required
                :is-invalid="$v.mainFoodConsumption.$invalid"
              >
                <c-form-label
                  fontSize="16px"
                  color="#555555"
                  fontWeight="400"
                  fontFamily="Roboto"
                >
                  Biasanya, berapa kali kamu makan berat atau makan utama dalam
                  1 hari?
                </c-form-label>

                <c-select
                  height="62px"
                  placeholder="Biasanya, berapa kali kamu makan berat atau makan utama dalam 1 hari?"
                  v-model="mainFoodConsumption"
                >
                  <option value="1 kali/hari">1 kali/hari</option>
                  <option value="2 kali/hari">2 kali/hari</option>
                  <option value="3 kali/hari">3 kali/hari</option>
                  <option value="Lebih dari 3 kali/hari">
                    Lebih dari 3 kali/hari
                  </option>
                </c-select>
              </c-form-control>
              <c-form-control
                :is-disabled="!isEditable"
                marginBottom="30px"
                is-required
                :is-invalid="$v.snackConsumption.$invalid"
              >
                <c-form-label
                  fontSize="16px"
                  color="#555555"
                  fontWeight="400"
                  fontFamily="Roboto"
                >
                  Biasanya, berapa kali kamu makan cemilan dalam 1 hari?
                </c-form-label>

                <c-select
                  height="62px"
                  placeholder="Biasanya, berapa kali kamu makan cemilan dalam 1 hari?"
                  v-model="snackConsumption"
                >
                  <option value="1 kali/hari">1 kali/hari</option>
                  <option value="2 kali/hari">2 kali/hari</option>
                  <option value="3 kali/hari">3 kali/hari</option>
                  <option value="Lebih dari 3 kali/hari">
                    Lebih dari 3 kali/hari
                  </option>
                </c-select>
              </c-form-control>
              <c-form-control
                :is-disabled="!isEditable"
                marginBottom="30px"
                is-required
                :is-invalid="$v.favoriteFood.$invalid"
              >
                <c-form-label
                  fontSize="16px"
                  color="#555555"
                  fontWeight="400"
                  fontFamily="Roboto"
                >
                  Makanan apa yang hampir setiap hari kamu konsumsi?
                </c-form-label>
                <c-input
                  type="text"
                  placeholder="Makanan apa yang hampir setiap hari kamu konsumsi?"
                  height="62px"
                  paddingRight="70px"
                  v-model="favoriteFood"
                />
              </c-form-control>
              <c-form-control
                :is-disabled="!isEditable"
                marginBottom="30px"
                is-required
                :is-invalid="$v.dislikeFood.$invalid"
              >
                <c-form-label
                  fontSize="16px"
                  color="#555555"
                  fontWeight="400"
                  fontFamily="Roboto"
                >
                  Makanan apa yang tidak kamu suka?
                </c-form-label>

                <c-input
                  type="text"
                  placeholder="Makanan apa yang tidak kamu suka?"
                  height="62px"
                  paddingRight="70px"
                  v-model="dislikeFood"
                />
                <c-form-helper-text font-size="12px">
                  Mohon dijelaskan agar kami dapat membuat saran menu yang
                  sesuai dengan selera kamu
                </c-form-helper-text>
              </c-form-control>
              <c-form-control
                :is-disabled="!isEditable"
                marginBottom="30px"
                is-required
                :is-invalid="$v.foodConsumptionType.$invalid"
              >
                <c-form-label
                  fontSize="16px"
                  color="#555555"
                  fontWeight="400"
                  fontFamily="Roboto"
                >
                  Kamu sukanya cita rasa makanan atau jenis makanan yang seperti
                  apa?
                </c-form-label>

                <c-input
                  type="text"
                  placeholder="Kamu sukanya cita rasa makanan atau jenis makanan yang seperti apa?"
                  height="62px"
                  paddingRight="70px"
                  v-model="foodConsumptionType"
                />
                <c-form-helper-text fontSize="12px">
                  Contoh: Western food, japanese food, makanan khas minang,
                  makanan khas manado, makanan khas jawa
                </c-form-helper-text>
              </c-form-control>
              <c-form-control
                :is-disabled="!isEditable"
                marginBottom="30px"
                is-required
                :is-invalid="$v.expectedBreakfast.$invalid"
              >
                <c-form-label
                  fontSize="16px"
                  color="#555555"
                  fontWeight="400"
                  fontFamily="Roboto"
                >
                  Makanan apa saja yang kamu harapkan muncul di menu sarapan?
                </c-form-label>

                <c-input
                  type="text"
                  placeholder="Makanan apa saja yang kamu harapkan muncul di menu sarapan?"
                  height="62px"
                  paddingRight="70px"
                  v-model="expectedBreakfast"
                />
                <c-form-helper-text font-size="12px">
                  Mohon dituliskan jenis makanan yang biasa kamu konsumsi atau
                  yang kamu harapkan muncul di menu sarapan kamu. Jika sesuai
                  dengan pengaturan dietmu, Ahli Gizi akan mencoba menyisipkan
                  makanan tersebut di rekomendasi menu.
                </c-form-helper-text>
              </c-form-control>
              <c-form-control
                :is-disabled="!isEditable"
                marginBottom="30px"
                is-required
                :is-invalid="$v.expectedLunchDinner.$invalid"
              >
                <c-form-label
                  fontSize="16px"
                  color="#555555"
                  fontWeight="400"
                  fontFamily="Roboto"
                >
                  Makanan apa saja yang kamu harapkan muncul di menu makan siang
                  dan makan malam?
                </c-form-label>

                <c-input
                  type="text"
                  placeholder="Makanan apa saja yang kamu harapkan muncul di menu makan siang dan makan malam?"
                  height="62px"
                  paddingRight="70px"
                  v-model="expectedLunchDinner"
                />
                <c-form-helper-text font-size="12px">
                  Mohon dituliskan jenis makanan yang biasa kamu konsumsi atau
                  yang kamu harapkan muncul di menu sarapan kamu. Jika sesuai
                  dengan pengaturan dietmu, Ahli Gizi akan mencoba menyisipkan
                  makanan tersebut di rekomendasi menu.
                </c-form-helper-text>
              </c-form-control>
              <c-form-control
                :is-disabled="!isEditable"
                marginBottom="30px"
                is-required
                :is-invalid="$v.foodAllergies.$invalid"
              >
                <c-form-label
                  fontSize="16px"
                  color="#555555"
                  fontWeight="400"
                  fontFamily="Roboto"
                >
                  Adakah makanan apa yang menyebabkan kamu alergi, jika ada
                  sebutkan jenis makannya ?
                </c-form-label>

                <c-input
                  type="text"
                  placeholder="Adakah makanan apa yang menyebabkan kamu alergi, jika ada sebutkan jenis makannya ?"
                  height="62px"
                  paddingRight="70px"
                  v-model="foodAllergies"
                />
              </c-form-control>
              <c-form-control
                :is-disabled="!isEditable"
                marginBottom="30px"
                is-required
                :is-invalid="$v.vegetablesConsumption.$invalid"
              >
                <c-form-label
                  fontSize="16px"
                  color="#555555"
                  fontWeight="400"
                  fontFamily="Roboto"
                >
                  Berapa banyak kamu mengkonsumi sayuran dalam sehari?
                </c-form-label>

                <c-stack>
                  <c-radio-group
                    variant-color="brand"
                    :is-disabled="!isEditable"
                    size="lg"
                    v-model="vegetablesConsumption"
                  >
                    <c-radio :is-disabled="!isEditable" value="Tidak pernah"
                      >Tidak pernah</c-radio
                    >
                    <c-radio :is-disabled="!isEditable" value="1 porsi/hari"
                      >1 porsi/hari</c-radio
                    >
                    <c-radio :is-disabled="!isEditable" value="2 porsi/hari"
                      >2 porsi/hari</c-radio
                    >
                    <c-radio :is-disabled="!isEditable" value="3 porsi/hari"
                      >3 porsi/hari</c-radio
                    >
                    <c-radio :is-disabled="!isEditable" value="3 porsi/hari">
                      Lebih dari 3 porsi/hari
                    </c-radio>
                  </c-radio-group>
                </c-stack>
                <c-form-helper-text v-if="!$v.vegetablesConsumption.required">
                  <c-text fontSize="12px" color="#F44336"> Wajib diisi</c-text>
                </c-form-helper-text>
              </c-form-control>

              <c-form-control
                :is-disabled="!isEditable"
                marginBottom="30px"
                is-required
                :is-invalid="$v.fruitConsumption.$invalid"
              >
                <c-form-label
                  fontSize="16px"
                  color="#555555"
                  fontWeight="400"
                  fontFamily="Roboto"
                >
                  Berapa banyak kamu mengonsumi buah dalam sehari?
                </c-form-label>

                <c-stack>
                  <c-radio-group
                    variant-color="brand"
                    :is-disabled="!isEditable"
                    size="lg"
                    v-model="fruitConsumption"
                  >
                    <c-radio :is-disabled="!isEditable" value="Tidak pernah"
                      >Tidak pernah</c-radio
                    >
                    <c-radio :is-disabled="!isEditable" value="1 porsi/hari"
                      >1 porsi/hari</c-radio
                    >
                    <c-radio :is-disabled="!isEditable" value="2 porsi/hari"
                      >2 porsi/hari</c-radio
                    >
                    <c-radio :is-disabled="!isEditable" value="3 porsi/hari"
                      >3 porsi/hari</c-radio
                    >
                    <c-radio :is-disabled="!isEditable" value="3 porsi/hari">
                      Lebih dari 3 porsi/hari
                    </c-radio>
                  </c-radio-group>
                </c-stack>
                <c-form-helper-text v-if="!$v.fruitConsumption.required">
                  <c-text fontSize="12px" color="#F44336"> Wajib diisi</c-text>
                </c-form-helper-text>
              </c-form-control>
              <c-form-control
                :is-disabled="!isEditable"
                marginBottom="30px"
                :is-invalid="$v.fluidConsumption.$invalid"
              >
                <c-form-label
                  fontSize="16px"
                  color="#555555"
                  fontWeight="400"
                  fontFamily="Roboto"
                >
                  Berapa rata-rata jumlah konsumsi cairan kamu setiap hari?
                  (Termasuk minuman selain air mineral)
                </c-form-label>

                <c-stack>
                  <c-radio-group
                    variant-color="brand"
                    :is-disabled="!isEditable"
                    size="lg"
                    v-model="fluidConsumption"
                  >
                    <c-radio
                      :is-disabled="!isEditable"
                      value="8 gelas/hari (2 liter/hari)"
                    >
                      8 gelas/hari (2 liter/hari)
                    </c-radio>
                    <c-radio
                      :is-disabled="!isEditable"
                      value="Kurang dari 8 gelas/hari (kurang dari 2 liter/hari)"
                    >
                      Kurang dari 8 gelas/hari (kurang dari 2 liter/hari)
                    </c-radio>
                    <c-radio
                      :is-disabled="!isEditable"
                      value="Lebih dari 8 gelas/hari (lebih dari 2 liter/hari)"
                    >
                      Lebih dari 8 gelas/hari (lebih dari 2 liter/hari)
                    </c-radio>
                  </c-radio-group>
                </c-stack>
                <c-form-helper-text v-if="!$v.fluidConsumption.required">
                  <c-text fontSize="12px" color="#F44336"> Wajib diisi</c-text>
                </c-form-helper-text>
              </c-form-control>
              <c-form-control
                :is-disabled="!isEditable"
                marginBottom="30px"
                :is-invalid="$v.beveragesComsumption.$invalid"
              >
                <c-form-label
                  fontSize="16px"
                  color="#555555"
                  fontWeight="400"
                  fontFamily="Roboto"
                >
                  Berapa banyak kamu mengonsumsi minuman kemasan atau minuman
                  manis dalam sehari?
                </c-form-label>

                <c-stack>
                  <c-radio-group
                    variant-color="brand"
                    :is-disabled="!isEditable"
                    size="lg"
                    v-model="beveragesComsumption"
                  >
                    <c-radio :is-disabled="!isEditable" value="Tidak pernah"
                      >Tidak pernah</c-radio
                    >
                    <c-radio
                      :is-disabled="!isEditable"
                      value="1 gelas atau 250 ml/hari"
                    >
                      1 gelas atau 250 ml/hari
                    </c-radio>
                    <c-radio
                      :is-disabled="!isEditable"
                      value="2 gelas atau 500 ml/hari"
                    >
                      2 gelas atau 500 ml/hari
                    </c-radio>
                    <c-radio
                      :is-disabled="!isEditable"
                      value="3 gelas atau 750 ml/hari"
                    >
                      3 gelas atau 750 ml/hari
                    </c-radio>
                    <c-radio
                      :is-disabled="!isEditable"
                      value="4 gelas atau 750 ml/hari"
                    >
                      4 gelas atau 750 ml/hari
                    </c-radio>
                    <c-radio
                      :is-disabled="!isEditable"
                      value="Lebih dari 4 gelas atau 1 liter/hari"
                    >
                      Lebih dari 4 gelas atau 1 liter/hari
                    </c-radio>
                  </c-radio-group>
                </c-stack>
                <c-form-helper-text v-if="!$v.beveragesComsumption.required">
                  <c-text fontSize="12px" color="#F44336"> Wajib diisi</c-text>
                </c-form-helper-text>
              </c-form-control>
              <c-form-control
                :is-disabled="!isEditable"
                marginBottom="30px"
                :is-invalid="$v.friedSnackConsumption.$invalid"
              >
                <c-form-label
                  fontSize="16px"
                  color="#555555"
                  fontWeight="400"
                  fontFamily="Roboto"
                >
                  Berapa banyak kamu mengonsumsi cemilan gorengan (diluar lauk
                  dalam makan utama) dalam sehari?
                </c-form-label>

                <c-stack>
                  <c-radio-group
                    variant-color="brand"
                    :is-disabled="!isEditable"
                    size="lg"
                    v-model="friedSnackConsumption"
                  >
                    <c-radio :is-disabled="!isEditable" value="Tidak pernah"
                      >Tidak pernah</c-radio
                    >
                    <c-radio :is-disabled="!isEditable" value="1 potong/hari"
                      >1 potong/hari</c-radio
                    >
                    <c-radio :is-disabled="!isEditable" value="2 potong/hari"
                      >2 potong/hari</c-radio
                    >
                    <c-radio :is-disabled="!isEditable" value="3 potong/hari"
                      >3 potong/hari</c-radio
                    >
                    <c-radio
                      :is-disabled="!isEditable"
                      value="Lebih dari 3 potong/hari"
                    >
                      Lebih dari 3 potong/hari
                    </c-radio>
                  </c-radio-group>
                </c-stack>
                <c-form-helper-text v-if="!$v.friedSnackConsumption.required">
                  <c-text fontSize="12px" color="#F44336"> Wajib diisi</c-text>
                </c-form-helper-text>
              </c-form-control>
              <c-form-control
                :is-disabled="!isEditable"
                marginBottom="30px"
                :is-invalid="$v.saltySnackConsumption.$invalid"
              >
                <c-form-label
                  fontSize="16px"
                  color="#555555"
                  fontWeight="400"
                  fontFamily="Roboto"
                >
                  Berapa sering kamu mengonsumsi makanan ringan asin atau gurih
                  dalam sehari (seperti makanan ringan kemasan, keripik, kacang
                  goreng asin)?
                </c-form-label>

                <c-stack>
                  <c-radio-group
                    variant-color="brand"
                    :is-disabled="!isEditable"
                    size="lg"
                    v-model="saltySnackConsumption"
                  >
                    <c-radio :is-disabled="!isEditable" value="Tidak pernah"
                      >Tidak pernah</c-radio
                    >
                    <c-radio :is-disabled="!isEditable" value="1 bungkus/hari"
                      >1 bungkus/hari</c-radio
                    >
                    <c-radio :is-disabled="!isEditable" value="2 bungkus/hari"
                      >2 bungkus/hari</c-radio
                    >
                    <c-radio :is-disabled="!isEditable" value="3 bungkus/hari"
                      >3 bungkus/hari</c-radio
                    >
                    <c-radio
                      :is-disabled="!isEditable"
                      value="Lebih dari 3 bungkus/hari"
                      >Lebih dari 3 bungkus/hari
                    </c-radio>
                  </c-radio-group>
                </c-stack>
                <c-form-helper-text v-if="!$v.saltySnackConsumption.required">
                  <c-text fontSize="12px" color="#F44336"> Wajib diisi</c-text>
                </c-form-helper-text>
              </c-form-control>
              <c-form-control
                :is-disabled="!isEditable"
                marginBottom="30px"
                :is-invalid="$v.sweetSnackConsumption.$invalid"
              >
                <c-form-label
                  fontSize="16px"
                  color="#555555"
                  fontWeight="400"
                  fontFamily="Roboto"
                >
                  Berapa sering kamu mengonsumsi cemilan manis dalam sehari
                  (seperti kue-kue manis, brownies, cake, biskuit, coklat,
                  wafer)?
                </c-form-label>

                <c-stack>
                  <c-radio-group
                    variant-color="brand"
                    :is-disabled="!isEditable"
                    size="lg"
                    v-model="sweetSnackConsumption"
                  >
                    <c-radio :is-disabled="!isEditable" value="Tidak pernah"
                      >Tidak pernah</c-radio
                    >
                    <c-radio :is-disabled="!isEditable" value="1 potong/hari"
                      >1 potong/hari</c-radio
                    >
                    <c-radio :is-disabled="!isEditable" value="2 potong/hari"
                      >2 potong/hari</c-radio
                    >
                    <c-radio :is-disabled="!isEditable" value="3 potong/hari"
                      >3 potong/hari</c-radio
                    >
                    <c-radio
                      :is-disabled="!isEditable"
                      value="Lebih dari 3 potong/hari"
                    >
                      Lebih dari 3 potong/hari
                    </c-radio>
                  </c-radio-group>
                </c-stack>
                <c-form-helper-text v-if="!$v.sweetSnackConsumption.required">
                  <c-text fontSize="12px" color="#F44336"> Wajib diisi</c-text>
                </c-form-helper-text>
              </c-form-control>
              <c-form-control
                :is-disabled="!isEditable"
                marginBottom="30px"
                :is-invalid="$v.personServeFood.$invalid"
              >
                <c-form-label
                  fontSize="16px"
                  color="#555555"
                  fontWeight="400"
                  fontFamily="Roboto"
                >
                  Siapakah yang menyediakan makanan bagi kamu?
                </c-form-label>

                <c-radio-group
                  :is-disabled="!isEditable"
                  size="lg"
                  variant-color="brand"
                  spacing="2"
                  v-model="personServeFood"
                >
                  <c-radio :is-disabled="!isEditable" value="Saya sendiri">
                    Saya sendiri</c-radio
                  >
                  <c-radio :is-disabled="!isEditable" value="Anggota keluarga">
                    Anggota keluarga</c-radio
                  >
                  <c-radio
                    :is-disabled="!isEditable"
                    value="Asisten rumah tangga"
                  >
                    Asisten Rumah Tangga
                  </c-radio>
                  <c-radio
                    :is-disabled="!isEditable"
                    value="Beli makanan diluar"
                  >
                    Beli makanan di luar (beli langsung maupun delivery/pesan
                    antar)
                  </c-radio>
                </c-radio-group>
                <c-form-helper-text v-if="!$v.personServeFood.required">
                  <c-text fontSize="12px" color="#F44336"> Wajib diisi</c-text>
                </c-form-helper-text>
              </c-form-control>
              <c-form-control
                :is-disabled="!isEditable"
                marginBottom="30px"
                :is-invalid="$v.milkFoodAndDrinkConsumption.$invalid"
              >
                <c-form-label
                  fontSize="16px"
                  color="#555555"
                  fontWeight="400"
                  fontFamily="Roboto"
                >
                  Apakah kamu bisa dan terbiasa konsumsi makanan dan minuman
                  yang mengandung susu?
                </c-form-label>

                <c-select
                  height="62px"
                  placeholder="Apakah kamu bisa dan terbiasa konsumsi makanan dan minuman yang mengandung susu?"
                  v-model="milkFoodAndDrinkConsumption"
                >
                  <option value="Bisa dan terbiasa">Bisa dan terbiasa</option>
                  <option value="Bisa tetapi tidak biasa">
                    Bisa tetapi tidak biasa
                  </option>
                  <option value="Tidak biasa">Tidak biasa</option>
                </c-select>
              </c-form-control>
              <c-form-control
                :is-disabled="!isEditable"
                marginBottom="80px"
                :is-invalid="$v.lifestyle.$invalid"
              >
                <c-form-label
                  fontSize="16px"
                  color="#555555"
                  fontWeight="400"
                  fontFamily="Roboto"
                >
                  Tak kenal maka tak sayang.. Tolong ceritakan dong hal lain
                  tentang pola makan dan gaya hidup kamu yang perlu kami tahu :)
                </c-form-label>
                <textarea
                  rows="3"
                  v-chakra
                  id="story"
                  placeholder="Masukkan cerita anda di sini"
                  d="block"
                  border="1px solid #E2E8F0"
                  border-radius="md"
                  minHeight="150px"
                  type="text"
                  lineHeight="20px"
                  p="10px"
                  w="100%"
                  v-model="lifestyle"
                />
              </c-form-control>

              <DinamisForm v-model="questions" />

              <c-flex
                justifyContent="space-between"
                alignItems="center"
                marginTop="30px"
              >
                <c-button
                  left-icon="chevron-left"
                  backgroundColor="brand.900"
                  color="white"
                  borderRadius="100px"
                  variant="solid"
                  px="30px"
                  as="router-link"
                  :to="{ name: 'client.kuisioner2', params: $route.params }"
                >
                  Kembali
                </c-button>
                <c-button
                  right-icon="chevron-right"
                  backgroundColor="brand.900"
                  color="white"
                  borderRadius="100px"
                  variant="solid"
                  px="30px"
                  type="submit"
                >
                  Selanjutnya
                </c-button>
              </c-flex>
            </c-box>
          </form>
        </c-box>
      </c-box>
    </c-box>
    <Footer />
  </c-box>
</template>

<script>
import { mapState } from "vuex"
import { required } from "vuelidate/lib/validators"
import Header from "@/components/header/index.vue"
import Footer from "@/components/Footer.vue"
import Breadcrumb from "@/components/breadcrumb"
import Steps from "@/components/steps"
import DinamisForm from "@/views/client/kuisioner/dinamis-form"

export default {
  name: "kuisioner3",
  components: {
    Header,
    Footer,
    Breadcrumb,
    Steps,
    DinamisForm,
  },
  data() {
    return {
      foodPreference: null,
      breakfastHabits: null,
      breakfastFoodType: null,
      mainFoodConsumption: null,
      snackConsumption: null,
      foodConsumptionType: null,
      dislikeFood: null,
      favoriteFood: null,
      expectedBreakfast: null,
      expectedLunchDinner: null,
      foodAllergies: null,
      vegetablesConsumption: null,
      fruitConsumption: null,
      beveragesComsumption: null,
      fluidConsumption: null,
      friedSnackConsumption: null,
      saltySnackConsumption: null,
      sweetSnackConsumption: null,
      personServeFood: null,
      milkFoodAndDrinkConsumption: null,
      lifestyle: null,

      // -- Dinamis form
      questions: [],
    }
  },
  validations: {
    foodPreference: { required },
    breakfastHabits: { required },
    breakfastFoodType: { required },
    mainFoodConsumption: { required },
    snackConsumption: { required },
    foodConsumptionType: { required },
    dislikeFood: { required },
    favoriteFood: { required },
    expectedBreakfast: { required },
    expectedLunchDinner: { required },
    foodAllergies: { required },
    vegetablesConsumption: { required },
    fruitConsumption: { required },
    beveragesComsumption: { required },
    fluidConsumption: { required },
    friedSnackConsumption: { required },
    saltySnackConsumption: { required },
    sweetSnackConsumption: { required },
    personServeFood: { required },
    milkFoodAndDrinkConsumption: { required },
    lifestyle: {},
  },
  computed: {
    ...mapState({
      kuisData: (s) => s.kuisioner.dietAndEatingHistory,
    }),
    isEditable() {
      return this.$route.params.isEditable ?? true
    },
    value() {
      return {
        foodPreference: this.foodPreference,
        breakfastHabits: this.breakfastHabits,
        breakfastFoodType: this.breakfastFoodType,
        mainFoodConsumption: this.mainFoodConsumption,
        snackConsumption: this.snackConsumption,
        foodConsumptionType: this.foodConsumptionType
          ?.split(",")
          ?.map((it) => it.trim()),
        dislikeFood: this.dislikeFood?.split(",")?.map((it) => it.trim()),
        favoriteFood: this.favoriteFood?.split(",")?.map((it) => it.trim()),
        expectedBreakfast: this.expectedBreakfast
          ?.split(",")
          ?.map((it) => it.trim()),
        expectedLunchDinner: this.expectedLunchDinner
          ?.split(",")
          ?.map((it) => it.trim()),
        foodAllergies: this.foodAllergies?.split(",")?.map((it) => it.trim()),
        vegetablesConsumption: this.vegetablesConsumption,
        fruitConsumption: this.fruitConsumption,
        beveragesComsumption: this.beveragesComsumption,
        fluidConsumption: this.fluidConsumption,
        friedSnackConsumption: this.friedSnackConsumption,
        saltySnackConsumption: this.saltySnackConsumption,
        sweetSnackConsumption: this.sweetSnackConsumption,
        personServeFood: this.personServeFood,
        milkFoodAndDrinkConsumption: this.milkFoodAndDrinkConsumption,
        lifestyle: this.lifestyle,
      }
    },
    answers() {
      return this.questions.map((it) => ({
        questionId: it.id,
        answer: it.answer,
        question: it.question,
      }))
    },
  },
  watch: {
    kuisData: {
      immediate: true,
      handler(val) {
        if (val == null) return

        this.foodPreference = val.foodPreference
        this.breakfastHabits = val.breakfastHabits
        this.breakfastFoodType = val.breakfastFoodType
        this.mainFoodConsumption = val.mainFoodConsumption
        this.snackConsumption = val.snackConsumption
        this.foodConsumptionType = val.foodConsumptionType?.join(", ")
        this.dislikeFood = val.dislikeFood?.join(", ")
        this.favoriteFood = val.favoriteFood?.join(", ")
        this.expectedBreakfast = val.expectedBreakfast?.join(", ")
        this.expectedLunchDinner = val.expectedLunchDinner?.join(", ")
        this.foodAllergies = val.foodAllergies?.join(", ")
        this.vegetablesConsumption = val.vegetablesConsumption
        this.fruitConsumption = val.fruitConsumption
        this.beveragesComsumption = val.beveragesComsumption
        this.fluidConsumption = val.fluidConsumption
        this.friedSnackConsumption = val.friedSnackConsumption
        this.saltySnackConsumption = val.saltySnackConsumption
        this.sweetSnackConsumption = val.sweetSnackConsumption
        this.personServeFood = val.personServeFood
        this.milkFoodAndDrinkConsumption = val.milkFoodAndDrinkConsumption
        this.lifestyle = val.lifestyle
      },
    },
    programId: {
      immediate: true,
      async handler(programId) {
        if (programId == null) return

        await this.$store.dispatch(
          "kuisioner/getV2ClientForCategory",
          "diet_and_eating_history"
        )
        await this.$store.dispatch("kuisioner/getV2ClientDraft", this.programId)
        this.questions = this.$store.getters["kuisioner/questionFor"](
          "diet_and_eating_history"
        )
      },
    },
  },
  methods: {
    async submit() {
      if (this.isEditable) {
        this.$v.$touch()
        if (this.$v.$invalid) return

        await this.$store.dispatch("kuisioner/setDietAndEatingHistory", {
          programId: this.$route.params.programId,
          value: this.value,
          answers: this.answers,
        })
      }
      await this.$router.push({
        name: "client.kuisioner4",
        params: this.$route.params,
      })
    },
  },
}
</script>

<style scoped>
::v-deep
  [data-chakra-component="CRadio"]
  [data-chakra-component="CControlBox"] {
  margin-top: 5px;
}
</style>
